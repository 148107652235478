.AmountToPayTextFieldContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.textFieldAndDateTextContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.enterAmountToPayText {
    color: rgba(26,26,26,.6);
    font-weight: 500;
    font-size: 14px;
    margin: 0 0 2px 2px;
}

.textFieldContainer {
    padding: 2px;
    width: 75%;
    border-bottom: 2px solid rgba(26,26,26,.1);
    transition: border-color 0.15s ease-out;
}

.textFieldContainer.focused {
    border-color: rgb(165, 202, 230);
}

.textFieldContainer.errorState {
    border-color: rgb(228, 156, 152);
}

.AmountToPayTextField {
    border: none;
    width: 100%;
    outline: none;
    font-size: 30px;
    font-weight: 300;
    color:  rgba(26, 26, 26, 0.7);
    box-sizing: border-box;
    transition: all 0.15s ease-out;
}

.contactForm {
    width: 120px;
    margin-top: 25px;
    margin-right: -32px;
}

@media screen and (max-width: 450px) {
    .contactForm {
        width: 90px;
        margin-right: -24px;
    }
}

@media screen and (max-width: 400px) {
    .contactForm {
        width: 50px;
        margin-right: -21px;
        margin-top: 25px;
    }
}