.separator {
    height: 1px;
    background-color: rgba(26,26,26,.1);
    margin: 0 10px;
}

.separator.asBorder {
    margin: 15px 0 0 0;
    width: 100%;
}

.separator.asSeparator {
    flex-basis: 33.33%;
}
