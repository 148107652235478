.dateText {
    color: rgba(26,26,26,.7);
    font-weight: 600;
    font-size: 25px;
    margin: 2px 0 0px 2px;
    width: 100%;
    text-align: center;
}

@media screen and (max-width: 450px) {
    .dateText {
        font-size: 17.5px;
    }
}